import { createMuiTheme } from "@material-ui/core/styles"
export const gold = "#FFECB3"

const base = {
  palette: {
    primary: { main: "#000000" },
    secondary: { main: "#4cd964" }
  },
  typography: {
    fontWeight: 500,
    fontFamily: [
      // "Graphik Web",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    button: {
      root: { textTansform: "none" },
      textTansform: "none"
    }
  }
}

export const theme = createMuiTheme(base)
