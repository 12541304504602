import React from "react"
import Unsplash from "./Unsplash"
import styled from "styled-components"
import { photoIds } from "./photos"

export const Thumb = ({ photoId, size, onSelect, selected, style }) => {
  return (
    <div
      onClick={() =>
        typeof onSelect === "function" ? onSelect(photoId) : null
      }
      style={style}
    >
      <Unsplash
        photoId={photoId}
        width={size}
        height={size}
        style={{
          cursor: "pointer",
          margin: 10,
          border: selected ? "3px solid black" : "0px"
          // clipPath: `polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%)`
        }}
      />
    </div>
  )
}

Thumb.defaultProps = {
  size: 80
}

const PickerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`

export const BackgroundPicker = ({ selectedPhotoId, onChange }) => {
  return (
    <PickerContainer>
      {photoIds.map((photoId, i) => (
        <Thumb
          key={photoId}
          photoId={photoId}
          selected={selectedPhotoId === photoId}
          onSelect={() => onChange(photoId)}
        />
      ))}
    </PickerContainer>
  )
}

const Background = ({ photoId, width, slant, children, ...props }) => {
  return (
    <Unsplash
      width={width}
      photoId={photoId}
      style={{
        backgroundColor: "#999",
        clipPath: `polygon(${slant}% 0%, 100% 0%, ${100 -
          slant}% 100%, 0% 100%)`
      }}
      {...props}
    >
      {children}
    </Unsplash>
  )
}

Background.defaultProps = {
  slant: 5 //5
}

export default Background
