import { db } from "../api/db"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { parseContract, validateContract } from "./useContract"

const useContracts = user => {
  const ref = db.collection(`contract`).where("user", "==", user.uid)
  const [data, loading, error] = useCollectionData(ref)
  const parsed = data ? data.map(parseContract).filter(validateContract) : []
  return [parsed, loading, error]
}

export default useContracts
