/*
https://unsplash.com/collections/9289603/goals/c7004e477782dbe91b95c6883acdab42
[...document.querySelectorAll("a").values()].filter(a=> !!a.attributes.itemprop).map(a=>a.attributes.href.value.split("/")[2])
*/

export const photoIds = [
  "5Rgr_zI7pBw",
  "9SoCnyQmkzI",
  "g1Kr4Ozfoac",
  "FQ96bh4O1tY",
  "9QTQFihyles",
  "uj3hvdfQujI",
  "d3bYmnZ0ank",
  "WvDYdXDzkhs",
  "M0AWNxnLaMw",
  "9cRDDvhpBRw",
  "_lBDypLbKgY",
  "sfL_QOnmy00"
]
