import React, { useState } from "react"
import styled from "styled-components"
import { useEffect } from "react"
import IconButton from "./IconButton"

const ListContainer = styled.ul`
  list-style: none;
  padding: 0px;
`

const ItemContainer = styled.li`
  display: flex;
  align-items: center;
`

const ListEditor = ({
  items: initialItems,
  renderItem,
  onChange,
  validateItem,
  blankItem
}) => {
  const [items, setItems] = useState(initialItems || [])

  useEffect(() => {
    const validItems = items.filter(validateItem)
    if (validItems.length === items.length) {
      setItems([...items, { ...blankItem }])
    }
  }, [validateItem, blankItem, items])

  const handleChange = index => data => {
    const nextItems = [...items]
    nextItems[index] = { ...nextItems[index], ...data }
    setItems(nextItems)
    onChange(nextItems.filter(validateItem))
  }

  const handleDelete = index => () => {
    const nextItems = [...items].filter((item, i) => i !== index)
    setItems(nextItems)
    onChange(nextItems.filter(validateItem))
  }

  const renderListItem = (item, index) => {
    const isValid = validateItem(item)
    return (
      <ItemContainer key={index}>
        {renderItem({ item, index, onChange: handleChange(index) })}
        {isValid ? (
          <IconButton
            style={{
              marginLeft: 10,
              cursor: "pointer"
            }}
            icon="X"
            onClick={handleDelete(index)}
            size={16}
          />
        ) : null}
      </ItemContainer>
    )
  }

  return <ListContainer>{items.map(renderListItem)}</ListContainer>
}

export default ListEditor
