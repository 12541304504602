import { auth } from "../api/db"
import { useAuthState } from "react-firebase-hooks/auth"
import { emailIsValid } from "../utils"
import { useState } from "react"

const providerMap = {
  google: auth.GoogleAuthProvider,
  facebook: auth.FacebookAuthProvider
}

const useMe = () => {
  const [user, loading, error] = useAuthState(auth())
  const [authenticating, setAuthenticating] = useState(false)

  const signOut = () => {
    return auth().signOut()
  }

  const signInWithProvider = async provider => {
    // TODO:
  }

  const signInAnonymously = async () => {
    return auth().signInAnonymously()
  }

  const linkWithProvider = async method => {
    const AuthProvider = providerMap[method]
    const provider = new AuthProvider()
    try {
      await user.linkWithPopup(provider)
      const index = user.providerData.findIndex(
        pd => pd.providerId === provider.providerId
      )
      const providerData = user.providerData[index]
      await user.updateProfile({
        displayName: providerData.displayName,
        photoURL: providerData.photoURL,
        email: providerData.email
      })
    } catch (error) {
      console.log("error linking: ", error)
      // TODO: if already linked etc, forceably log the user out?
    }
  }

  const resendSignInLink = () => {
    let email = localStorage.getItem("authEmail")
    if (!emailIsValid(email)) {
      email = window.prompt("Please provide your email for confirmation")
    }
    let name = localStorage.getItem("authName")
    return sendSignInLink(name, email)
  }

  const sendSignInLink = async (name, email, action = "sign") => {
    const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?action=${action}`
    localStorage.setItem("authEmail", email)
    localStorage.setItem("authName", name)
    console.log("sending link...", { email, url })
    await auth().sendSignInLinkToEmail(email, { url, handleCodeInApp: true })
  }

  const updateProfile = displayName => {
    return auth().currentUser.updateProfile({ displayName })
  }

  const authWithLink = async () => {
    if (authenticating) return
    setAuthenticating(true)
    const url = window.location.href

    if (auth().isSignInWithEmailLink(url)) {
      let email = localStorage.getItem("authEmail")

      if (!email) {
        email = window.prompt("Please provide your email for confirmation")
      }

      if (auth().currentUser) {
        // Construct the email link credential from the current URL.
        let credential = auth.EmailAuthProvider.credentialWithLink(email, url)

        // Link the credential to the current user.
        await auth().currentUser.linkWithCredential(credential)

        // update the profile if necessary (just the name for now)
      } else {
        await auth().signInWithEmailLink(email)
      }

      if (!auth().currentUser.displayName && localStorage.getItem("authName")) {
        await updateProfile(localStorage.getItem("authName"))
      }
    }
  }

  return {
    user,
    loading,
    error,
    signOut,
    isAuthenticated: user && !user.isAnonymous,
    sendSignInLink,
    updateProfile,
    resendSignInLink,
    authWithLink,
    signInAnonymously,
    linkWithProvider
  }
}

export default useMe
