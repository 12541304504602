import React, { useState, useEffect } from "react"
import moment from "moment"
import pluralize from "pluralize"
import * as Icon from "react-feather"
import styled from "styled-components"
import { Button, TextField } from "@material-ui/core"
import SignPaper from "./SignPaper"
import SignedContractActions from "./SignedContractActions"
import { Row, renderDate, Col, Warn } from "./ui"
import useMe from "./hooks/useMe"
import useQuery from "./hooks/useQuery"
import {
  signContract,
  validateContract,
  verifyContractSignature,
  getContractStatus,
} from "./hooks/useContract"
import { emailIsValid } from "./utils"

const SignatureContainer = styled(SignPaper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  p {
    line-height: 1.5em;
    margin-top: 0px;
  }
`

export const Signature = styled.div`
  font-size: 2em;
  font-family: "Caveat", cursive;
  border-bottom: 1px solid #000;
`

const SignPointer = styled(Row)`
  display: inline-flex;
  /* color: #fff; */
  flex-grow: 0;
  /* background-color: blue; */
  font-size: 14px;
  padding: 4px 10px;
  /* text-transform: uppercase; */

  /* position: absolute;
  left: -20px;
  top: 0px; */
`

const EmailSignatureForm = ({ onSign }) => {
  const [status, setStatus] = useState("Sign Contract") // sending, sent
  const [name, setName] = useState(localStorage.getItem("authName") || "")
  const [email, setEmail] = useState(localStorage.getItem("authEmail") || "")
  const { sendSignInLink } = useMe()

  const isValid = emailIsValid(email) && name.trim().length > 1

  const handleSign = async () => {
    setStatus("Signing...")
    await sendSignInLink(name, email)
    onSign({ name })
    setStatus("Signed")
  }

  return (
    <Col>
      {/* <SignPointer>Sign your name/email</SignPointer> */}
      <Row
        style={{
          marginBottom: 10,
          padding: 4,
          position: "relative",
          // border: "1px solid blue"
        }}
      >
        <TextField
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Your full name"
          fullWidth
        />
        <TextField
          name="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your email address"
          fullWidth
        />
      </Row>
      <Button
        disabled={!isValid || status === "Signing..."}
        variant="contained"
        color="primary"
        onClick={handleSign}
      >
        <Icon.Feather style={{ marginRight: 10 }} />
        {status}
      </Button>
    </Col>
  )
}

const SignatureAction = ({ contract }) => {
  const { user, updateProfile } = useMe()

  const handleSign = async (signature) => {
    if (!signature) signature = { name: user.displayName }
    if (!user.displayName && signature?.name) {
      await updateProfile(signature.name)
    }
    await signContract(contract, user, signature)
  }

  if (user && !user.isAnonymous && user.displayName) {
    return (
      <Button variant="contained" color="primary" onClick={handleSign}>
        <Icon.Feather style={{ marginRight: 10 }} />
        Sign as {user.displayName}
      </Button>
    )
  } else {
    return <EmailSignatureForm onSign={handleSign} />
  }
}

const VerificationStatus = ({ contract }) => {
  const { user } = useMe()
  const { resendSignInLink } = useMe()
  const [error, setError] = useState()
  const [action, setAction] = useState("Resend")

  // auto verify
  useEffect(() => {
    const doVerify = async () => {
      console.log("verfiying with user: ", user)
      await verifyContractSignature(contract)
    }
    if (!contract.signature.verified && user.emailVerified) {
      doVerify()
    }
  }, [contract, user])

  const handleSend = async () => {
    setAction("Sending...")
    try {
      await resendSignInLink()
      setAction("Sent")
      setTimeout(() => setAction("Resend"), 1000)
    } catch (error) {
      setError(error)
    }
  }

  return (
    <Warn>
      <Col>
        <span>
          Your signature has not been verified. Please click the sign in link we
          sent to verify.
        </span>
        {error ? <p>Error: {error.toString()}</p> : null}
        <Button disabled={action !== "Resend"} onClick={handleSend}>
          {action}
        </Button>
      </Col>
    </Warn>
  )
}

const Sign = ({ contract, permissions }) => {
  const query = useQuery()

  const { authWithLink } = useMe()
  const isSigned = !!contract.signature?.date

  useEffect(() => {
    const doAuthAndVerify = async () => {
      if (query.get("action")) {
        await authWithLink()
        await verifyContractSignature(contract)
      }
    }
    if (isSigned && !contract.signature.verified) doAuthAndVerify()
  }, [query, isSigned, contract, authWithLink])

  const isValid = validateContract(contract)
  // if (!permissions) return null
  if (!isValid) return null

  const status = getContractStatus(contract).status
  const canComplete = permissions?.edit && status === "in progress"

  return (
    <SignatureContainer>
      <div style={{ padding: "20px 30px" }}>
        <p>
          I commit to peforming the goal and terms outlined above from{" "}
          <strong>{renderDate(contract.startDate)}</strong> to{" "}
          <strong>{renderDate(contract.dueDate)}</strong> (
          {moment
            .duration(contract.dueDate.getTime() - contract.startDate.getTime())
            .humanize()}
          )
        </p>
        {isSigned ? (
          <Signature>
            <Icon.Feather />
            {contract.signature.name}
          </Signature>
        ) : (
          <SignatureAction contract={contract} />
        )}
        {isSigned ? (
          <>
            <Row style={{ padding: "8px 0px", fontSize: 14, flexWrap: "wrap" }}>
              {contract.signature.verified ? (
                <span
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    color: "blue",
                  }}
                >
                  <Icon.CheckCircle size={14} style={{ marginRight: 5 }} />{" "}
                  Verified
                </span>
              ) : (
                <span>Unverified</span>
              )}
              <span style={{ margin: "0px 4px" }}>&bull;</span>
              <span>Signed {renderDate(contract.signature.date)}</span>

              {contract.completedDate ? (
                <>
                  <span style={{ margin: "0px 4px" }}>&bull;</span>
                  <span>Completed {renderDate(contract.completedDate)}</span>
                </>
              ) : null}
            </Row>
            {permissions?.edit && !contract.signature.verified ? (
              <VerificationStatus contract={contract} />
            ) : null}
          </>
        ) : null}
      </div>
      {canComplete ? <SignedContractActions contract={contract} /> : null}
    </SignatureContainer>
  )
}

export default Sign
