import styled from "styled-components"
import * as Icon from "react-feather"
import React, { useState, useEffect } from "react"
import { Dialog, TextField, Button } from "@material-ui/core"
import { emailIsValid } from "./utils"
import useMe from "./hooks/useMe"
import { useHistory } from "react-router-dom"
import useQuery from "./hooks/useQuery"
import { Row } from "./ui"

const Container = styled.div`
  padding: 20px;
`

const AuthModal = ({ onSignIn }) => {
  const [email, setEmail] = useState("")
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)
  const handleSignIn = async () => {
    setSending(true)
    await onSignIn(email)
    setSending(false)
    setSent(true)
  }
  return (
    <Dialog open={true}>
      <Container>
        <Row>
          <Icon.Lock style={{ marginRight: 10 }} />
          <h3>Sign in</h3>
        </Row>
        {!sent ? (
          <>
            <p>
              Enter your email address and we'll send you a magic link you can
              click to sign in
            </p>
            <TextField
              value={email}
              fullWidth
              onChange={e => setEmail(e.target.value)}
              placeholder="Enter your email address"
            ></TextField>
            <Button
              variant="contained"
              disabled={!emailIsValid(email) || sending}
              onClick={handleSignIn}
              color="primary"
              style={{ marginTop: 10 }}
            >
              Send sign in link
            </Button>
          </>
        ) : (
          <>
            <p>Check your email and click the link</p>
          </>
        )}
      </Container>
    </Dialog>
  )
}

const AuthModalContainer = () => {
  const query = useQuery()
  const { user, sendSignInLink, authWithLink } = useMe()
  const history = useHistory()

  const handleSignIn = async email => {
    await sendSignInLink("", email)
  }

  useEffect(() => {
    if (user && !user.isAnonymous) {
      // already signed in...
      history.push("/")
    }
  }, [user, history])

  useEffect(() => {
    const doAuth = async () => {
      if (query.get("action")) {
        await authWithLink()
      }
    }
    doAuth()
  }, [query, authWithLink])

  return <AuthModal onSignIn={handleSignIn} />
}

export default AuthModalContainer
