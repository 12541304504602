import React from "react"
import styled from "styled-components"
import * as Icon from "react-feather"
import moment from "moment"

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const WarnContainer = styled(Row)`
  margin: 10px 0px;
  padding: 8px 10px;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(0, 0, 0, 0.19) 8px 12px 40px,
    rgba(0, 0, 0, 0.23) 8px 6px 12px;
`

export const Warn = ({ children }) => {
  return (
    <WarnContainer>
      <Icon.AlertTriangle style={{ flexShrink: 0, marginRight: 10 }} />
      {children}
    </WarnContainer>
  )
}

export const renderDate = (date, format = "ll") => {
  return date ? moment(date).format(format) : "?"
}
