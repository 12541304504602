import React, { useState } from "react"
import styled from "styled-components"
import { Button } from "@material-ui/core"
import * as Icon from "react-feather"
import { completeContract, voidContract } from "./hooks/useContract"
import { Row } from "./ui"

const ConfirmContainer = styled(Row)`
  padding: 0px 10px;
`

const Container = styled(Row)`
  background-color: #000;
  color: #fff;
  justify-content: center;
  padding: 5px;

  &.confirm {
    justify-content: center;
    button {
      margin-right: 10px;
    }
  }

  .MuiButton-root {
    svg {
      margin-right: 5px;
    }
    color: #ccc;
  }
`

const SignedContractActions = ({ contract }) => {
  const [confirm, setConfirm] = useState()

  const handleAction = action => async () => {
    if (action === "complete") {
      await completeContract(contract)
    } else if (action === "void") {
      await voidContract(contract)
    }
  }

  if (confirm === "complete") {
    return (
      <Container className="confirm">
        Have you completed the contract?
        <Button onClick={handleAction("complete")}>Yes</Button>
        <Button onClick={() => setConfirm(null)}>Nevermind</Button>
      </Container>
    )
  }
  if (confirm === "void") {
    return (
      <Container className="void">
        Are you sure you want to void the contract?
        <Button warning onClick={handleAction("void")}>
          Yes
        </Button>
        <Button onClick={() => setConfirm(null)}>Nevermind</Button>
      </Container>
    )
  }

  return (
    <Container>
      <Button onClick={() => setConfirm("complete")}>
        <Icon.CheckSquare /> Complete Contract
      </Button>
      <Button onClick={() => setConfirm("void")} style={{ marginLeft: "auto" }}>
        <Icon.XCircle /> Void
      </Button>
    </Container>
  )
}

export default SignedContractActions
