import "./App.css"
import "./assets/fonts.css"
import styled from "styled-components"
import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { ThemeProvider } from "@material-ui/styles"
import ContractView from "./ContractView"
import { theme } from "./theme"
import useMe from "./hooks/useMe"
import DashBar from "./DashBar"
import Home from "./Home"
import AuthModal from "./AuthModal"
import { Col } from "./ui"
import Logo from "./ui/Logo"
import Signed from "./Signed"
import CopyContractView from "./CopyContractView"

const Container = styled(Col)`
  width: 100%;
  overflow-x: hidden;
`

const Footer = styled.footer`
  padding: 10px;
  text-align: center;
  a {
    color: #000;
  }
`

const App = () => {
  // const { user, loading, error } = useMe()
  // if (loading) return <div>Loading...</div>
  // if (error) return <div>Error: {error.toString()}</div>
  return (
    <ThemeProvider theme={theme}>
      {/* <CssBaseline /> */}
      <Container>
        <Router>
          <DashBar />
          <Route exact path="/auth">
            <AuthModal />
          </Route>
          <Switch>
            <Route path="/logo">
              <Logo
                animate
                style={{
                  width: 250,
                  height: 250,
                  borderRadius: "50%",
                  margin: 50,
                }}
                size={140}
                confetti
              />
            </Route>
            <Route exact path="/goal/:id/copy">
              <CopyContractView />
            </Route>
            <Route path="/goal/:id/:view?">
              <ContractView />
            </Route>
            <Route path="/signed">
              <Signed />
            </Route>

            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </Container>
      <Footer>
        Built by <a href="https://twitter.com/wmdmark">Mark Johnson</a>. &copy;
        Copyright {new Date().getFullYear()}
      </Footer>
    </ThemeProvider>
  )
}

export default App
