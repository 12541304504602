import React from "react"
import styled from "styled-components"
import ContractForm from "./ContractForm"
import * as Icon from "react-feather"
import Contract from "./Contract"
import Drawer from "./Drawer"
import useContract, { getContractURL } from "./hooks/useContract"
import { useWindowSize } from "@react-hook/window-size"
import { useState } from "react"
import { useParams, Link, useHistory } from "react-router-dom"
import { Button } from "@material-ui/core"
import { Row } from "./ui"
import GoalJournal from "./journal/GoalJournal"
import { ShareSheet } from "./ShareModal"

const ContractViewWrap = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: row;
  /* justify-content: center; */
  width: 100%;
  /* height: calc(100vh - 40px); */
  min-height: 100vh;
  /* background-color: #f2f3f5; */
  background-color: #fafafa;
  flex-grow: 1;
`

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  padding: 20px;
  background-color: #fff;
  border-right: 1px solid #ddd;
`

const PreviewContainer = styled.div`
  flex-grow: 1;
  position: relative;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 60px;
`

const CommunityContainer = styled.div`
  position: relative;
  max-width: 500px;
  padding: 0px 20px;
  text-align: left;

  button {
    margin-right: 10px;
    margin-bottom: 10px;

    svg {
      margin-right: 5px;
    }
  }

  .MuiButton-label {
    justify-content: flex-start;
  }
`

const ContractView = ({ contract, update, permissions, view }) => {
  const history = useHistory()
  const [width] = useWindowSize()

  const isSigned = !!contract.signature?.date
  const mobile = width < 1200
  const previewWidth = Math.min(900, width)

  const handleChange = async (data) => {
    update(data)
  }

  const editInDrawer = permissions?.edit && (mobile || isSigned)
  const showCommunity = isSigned && permissions?.edit

  const renderControls = () => {
    if (editInDrawer) {
      return (
        <>
          <Drawer
            open={view === "edit"}
            width={Math.min(width, 400)}
            onClose={() => history.push(getContractURL(contract, false))}
          >
            <FormContainer>
              <ContractForm contract={contract} onChange={handleChange} />
            </FormContainer>
          </Drawer>
        </>
      )
    } else {
      return (
        <FormContainer>
          <ContractForm contract={contract} onChange={handleChange} />
        </FormContainer>
      )
    }
  }

  const renderCommunity = () => {
    const Community = () => (
      <CommunityContainer>
        <Row>
          <Icon.Share size={16} style={{ marginRight: 5 }} />
          <h3>Share your goal</h3>
        </Row>
        <ShareSheet contract={contract} />
        {/* <Row>
          <Icon.Book />
          <h3>Share progress update</h3>
        </Row>
        <GoalJournal contract={contract} /> */}
      </CommunityContainer>
    )
    if (mobile) {
      return (
        <Drawer
          open={view === "share"}
          width={Math.min(width, 400)}
          onClose={() => history.push(getContractURL(contract, false))}
          anchor="right"
        >
          <Community />
        </Drawer>
      )
    } else {
      return <Community />
    }
  }

  return (
    <ContractViewWrap>
      {permissions?.edit ? renderControls() : null}
      <PreviewContainer style={{ width: previewWidth }}>
        <div style={{ top: 10, left: 10, position: "absolute", zIndex: 100 }}>
          {editInDrawer ? (
            <Button
              component={Link}
              variant="outlined"
              to={`/goal/${contract.id}/edit`}
            >
              <Icon.Edit size={16} />
              Edit
            </Button>
          ) : null}
          <Button
            component={Link}
            variant="outlined"
            style={{ marginLeft: 10 }}
            to={`/goal/${contract.id}/copy`}
          >
            <Icon.Copy size={16} />
            Copy
          </Button>
        </div>

        {mobile && showCommunity ? (
          <Button
            component={Link}
            variant="outlined"
            style={{
              top: 10,
              position: "absolute",
              right: 10,
              zIndex: 100,
            }}
            to={`/goal/${contract.id}/share`}
          >
            <Icon.MessageSquare size={16} />
            Share
          </Button>
        ) : null}
        <Contract contract={contract} permissions={permissions} />
      </PreviewContainer>
      {showCommunity ? renderCommunity() : null}

      {/* <GoalJournal contract={contract} /> */}
    </ContractViewWrap>
  )
}

const Loading = styled(Row)`
  min-height: 100vh;
  justify-content: center;
`

const ContractViewContainer = () => {
  const { id, view } = useParams()
  const { loading, error, contract, update, permissions } = useContract(id)
  if (loading)
    return (
      <Loading>
        <span>Loading</span>
      </Loading>
    )
  if (error) return <div>{error.toString()}</div>
  return (
    <ContractView
      permissions={permissions}
      contract={contract}
      update={update}
      view={view}
    />
  )
}

export default ContractViewContainer
