import moment from "moment"
import { blankContract } from "../hooks/useContract"
import { photoIds } from "../ui/photos"

const examples = [
  {
    ...blankContract,
    photoId: "uj3hvdfQujI",
    goal: "Launch side project",
    startDate: new Date(),
    dueDate: moment()
      .endOf("year")
      .toDate(),
    steps: [
      { description: "Finish MVP demo" },
      { description: "Gather feedback from beta group" },
      { description: "Launch on Product Hunt!" }
    ],
    signature: {
      name: "Mark Johnson",
      date: new Date(),
      verified: true
    },
    completedDate: new Date()
  },
  {
    ...blankContract,
    photoId: "WvDYdXDzkhs",
    goal: "Lose Weight",
    startDate: new Date(),
    dueDate: moment()
      .endOf("month")
      .toDate(),
    steps: [
      { description: "Low Carb Diet" },
      { description: "Intermittent fasting 3x per week" },
      { description: "Work out 3x per week" }
    ],
    signature: {
      name: "Casey J",
      date: new Date(),
      verified: true
    }
  },
  {
    ...blankContract,
    photoId: "sfL_QOnmy00",
    goal: "Read More Books",
    startDate: new Date(),
    dueDate: moment()
      .endOf("year")
      .toDate(),
    steps: [
      { description: "Read at least 1 fiction and non-fiction book a month" },
      { description: "Join or start a book club" },
      { description: "Write 1 book review per month on Goodreads" }
    ],
    signature: {
      name: "Vickie Baker",
      date: new Date(),
      verified: true
    }
  }
]

export default examples
