import { db, auth, Timestamp } from "../api/db"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useState, useEffect } from "react"
import moment from "moment"
import useMe from "./useMe"

export const blankContract = {
  goal: "",
  steps: [],
  nots: [],
  startDate: null,
  dueDate: null,
  signature: null,
  completedDate: null,
  canceledDate: null,
}

const colorsMap = {
  draft: "rgb(255,204,0)", // yellow
  "in progress": "#53d769", // green
  pending: "#ccc", // light grey,
  completed: "#5fc9f8", // light blue,
  voided: "#fc3d39", // red
}

export const getContractStatus = (contract) => {
  let status = ""

  const now = moment()
  const start = moment(contract.startDate).startOf("day")
  const due = moment(contract.dueDate).endOf("day")

  if (!contract.signature?.date) {
    status = "draft"
  } else if (contract.voidedDate) {
    status = "voided"
  } else if (contract.canceledDate) {
    status = "canceled"
  } else if (contract.completedDate) {
    status = "completed"
  } else if (now.isBefore(start)) {
    status = `upcoming`
  } else if (now.isAfter(start)) {
    status = "in progress"
  } else {
    console.log("unkown contract status?", due.format())
  }

  const color = colorsMap[status] || "#ccc"
  return { status, color }
}

export const getContractURL = (contract, absolute = true) => {
  const base = absolute
    ? `${window.location.protocol}//${window.location.host}`
    : ""
  return `${base}/goal/${contract.id}`
}

export const useMemoryContract = (initialData = {}) => {
  const [contract, setContract] = useState(initialData || blankContract)
  const update = async (data) => {
    const next = { ...contract, ...data }
    setContract(next)
  }
  return { contract, update }
}

const useContract = (id) => {
  const { user } = useMe()
  const [permissions, setPermissions] = useState()

  const [contract, loading, error] = useDocumentData(db.doc(`contract/${id}`))
  const update = async (data) => {
    const next = { ...contract, ...data }
    await db.doc(`contract/${id}`).set(next)
  }
  const copy = async () => {
    return createNewGoalContractDoc({ ...contract })
  }

  useEffect(() => {
    if (user && contract && !permissions)
      setPermissions({ edit: user?.uid === contract.user })
  }, [user, permissions, contract])

  return {
    contract: parseContract(contract),
    permissions,
    loading,
    error,
    copy,
    update,
  }
}

export const parseContract = (data) => {
  return {
    ...blankContract,
    ...data,
    dueDate: data && data.dueDate ? data.dueDate.toDate() : null,
    startDate: data && data.startDate ? data.startDate.toDate() : new Date(),
    signature: data?.signature
      ? {
          ...data.signature,
          date: data.signature.date ? data.signature.date.toDate() : null,
        }
      : {},
    canceledDate: data && data.canceledDate ? data.canceledDate.toDate() : null,
    completedDate:
      data && data.completedDate ? data.completedDate.toDate() : null,
  }
}

export const validateContract = (data) => {
  if (data.goal && data.steps.length > 0 && data.dueDate) return true
  return false
}

export const signContract = async (contract, user, signature) => {
  // TODO: make sure the user can sign this?
  const update = {
    signature: {
      user: user.uid,
      name: user.displayName || signature.name,
      date: new Date(),
      verified: user.emailVerified,
    },
  }
  const doc = await db.collection(`contract`).doc(contract.id)
  await doc.update(update)
  return update
}

export const voidContract = async (contract, user, signature) => {
  // TODO: make sure the user can sign this?
  const update = {
    voidedDate: new Date(),
  }
  const doc = await db.collection(`contract`).doc(contract.id)
  await doc.update(update)
  return update
}

export const verifyContractSignature = async (contract) => {
  const user = auth().currentUser
  const update = {
    signature: {
      ...contract.signature,
      verified: user.emailVerified,
    },
  }
  const doc = await db.collection(`contract`).doc(contract.id)
  await doc.update(update)
  return update
}

export const completeContract = async (contract) => {
  const update = {
    completedDate: new Date(),
  }
  const doc = await db.collection(`contract`).doc(contract.id)
  await doc.update(update)
  return update
}

export const cancelContract = async (contract) => {
  const update = {
    canceledDate: new Date(),
  }
  const doc = await db.collection(`contract`).doc(contract.id)
  await doc.update(update)
  return update
}

export const deleteContract = async (contract) => {
  const doc = await db.collection(`contract`).doc(contract.id)
  await doc.delete()
  return true
}

export const createNewGoalContractDoc = async (data) => {
  const user = auth().currentUser
  if (!user) throw new Error("Sign in to create a contract")
  const doc = db.collection(`contract`).doc()
  const baseData = {
    ...blankContract,
    id: doc.id,
    user: user.uid,
    startDate: new Date(),
  }
  const docData = { ...baseData, ...data }
  await doc.set(docData)
  return docData
}

export const copyContractByID = async (id) => {
  const user = auth().currentUser
  if (!user) throw new Error("Sign in to create a contract")
  const copydoc = db.collection(`contract`).doc(id)
  const data = (await copydoc.get()).data()
  console.log("copying contract...", data)

  let startDate = new Date()
  let dueDate = null
  if (data.startDate && data.dueDate) {
    const s = moment(data.startDate.toDate())
    const d = moment(data.dueDate.toDate())
    const daySpread = d.diff(s, "days")
    console.log({ daySpread })
    dueDate = moment(startDate).add(daySpread, "days").toDate()
    console.log({ dueDate })
  }

  const doc = db.collection(`contract`).doc()
  const docData = {
    ...blankContract,
    ...data,
    id: doc.id,
    signature: null,
    completedDate: null,
    canceledDate: null,
    goal: data.goal + " Copy",
    user: user.uid,
    startDate,
    dueDate,
  }

  await doc.set(docData)
  return docData
}

export default useContract
