import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAFqcXMkEt_YWfRdQEv9_g3G0hI4b35xJI",
  authDomain: "contrx-prod.firebaseapp.com",
  databaseURL: "https://contrx-prod.firebaseio.com",
  projectId: "contrx-prod",
  storageBucket: "contrx-prod.appspot.com",
  messagingSenderId: "703141539603",
  appId: "1:703141539603:web:e0f23e5ac4029114f874e6"
}

// Initialize Firebase
let Firebase = firebase.initializeApp(firebaseConfig)

export const db = firebase.firestore()
export const auth = firebase.auth
export const Timestamp = firebase.firestore.Timestamp

export default Firebase
