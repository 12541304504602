import React, { useState, useEffect } from "react"
import styled from "styled-components"
import * as Icon from "react-feather"
import { Link } from "react-router-dom"
import Confetti from "react-confetti"
import { colors } from "@material-ui/core"

const Container = styled(Link)`
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 50px;
  margin: 0px 10px;
  /* border-radius: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: #000;
  color: #fff;
`

const bgcolors = [
  "#000",
  "rgb(255,204,0)",
  "#53d769",
  "#ccc",
  "#5fc9f8",
  "#fc3d39"
]

const Logo = ({ style, confetti, animate, size }) => {
  const [color, setColor] = useState("#000")
  const [ci, setCI] = useState(0)

  useEffect(() => {
    let i
    if (animate) {
      i = setInterval(() => {
        const ni = ci < bgcolors.length - 1 ? ci + 1 : 0
        setCI(ni)
        setColor(bgcolors[ni])
      }, 1000)
    }
    return () => {
      if (i) {
        clearInterval(i)
      }
    }
  }, [animate, ci])

  return (
    <Container style={{ backgroundColor: color, ...style }} to="/">
      {confetti ? <Confetti /> : null}
      <Icon.Feather size={size} />
    </Container>
  )
}

Logo.defaultProps = {
  animate: false,
  confetti: false,
  size: 24
}

export default Logo
