import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { getContractURL, copyContractByID } from "./hooks/useContract"
import { useParams, Redirect } from "react-router-dom"
import { Row } from "./ui"
import useMe from "./hooks/useMe"

const Loading = styled(Row)`
  min-height: 100vh;
  justify-content: center;
`

const CopyContractView = () => {
  const [copying, setCopying] = useState(false)
  const { user, signInAnonymously } = useMe()
  const [copy, setCopy] = useState(null)
  const { id } = useParams()

  useEffect(() => {
    const doCopy = async () => {
      setCopying(true)
      if (!user) await signInAnonymously()
      const contractCopy = await copyContractByID(id)
      setCopy(contractCopy)
    }
    if (!copying) doCopy()
  }, [id, copy, user, signInAnonymously])

  if (!copy)
    return (
      <Loading>
        <span>Copying...</span>
      </Loading>
    )
  // if (error) return <div>{error.toString()}</div>
  return <Redirect to={getContractURL(copy, false) + "/edit"} />
}

export default CopyContractView
