import React, { useState } from "react"
import moment from "moment"
import styled from "styled-components"
import * as Icons from "react-feather"
import DateFnsUtils from "@date-io/date-fns"
import TextField from "@material-ui/core/TextField"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers"
import { Button } from "@material-ui/core"

import ListEditor from "./ListEditor"
import { Row, renderDate, Col } from "./ui"
import { BackgroundPicker } from "./ui/Background"
import { deleteContract } from "./hooks/useContract"
import { useHistory } from "react-router-dom"

const Container = styled.div`
  max-width: 440px;
`

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  h1 {
    font-size: 1.5em;
    margin: 0px;
    margin-bottom: 10px;
  }
`

const Section = ({ icon, title, children }) => {
  const Icon = Icons[icon]
  return (
    <SectionContainer>
      <h1>
        <Icon strokeWidth={3} style={{ position: "relative", top: 2 }} />
        {typeof title === "function" ? (
          title()
        ) : (
          <span style={{ marginLeft: 5 }}>{title}</span>
        )}
      </h1>
      {children}
    </SectionContainer>
  )
}

const DeleteAction = ({ contract }) => {
  const history = useHistory()
  const [status, setStatus] = useState()
  const handleDelete = async () => {
    setStatus("deleting")
    await deleteContract(contract)
    history.push("/")
  }
  return (
    <Row>
      {!status ? (
        <Button
          variant="outlined"
          style={{ borderColor: "#fc3d39", color: "#fc3d39" }}
          onClick={() => setStatus("confirm")}
        >
          Delete Contract
        </Button>
      ) : status === "confirm" ? (
        <Col>
          <p>
            Are you sure you want to delete <strong>{contract.goal}</strong>?
            This can't be undone.
          </p>
          <Row>
            <Button onClick={handleDelete} style={{ color: "#fc3d39" }}>
              Yes, Delete
            </Button>
            <Button onClick={() => setStatus(undefined)}>Nevermind</Button>
          </Row>
        </Col>
      ) : status === "deleting" ? (
        <span>Deleting...</span>
      ) : null}
    </Row>
  )
}

const ContractForm = ({ contract, onNext, onChange }) => {
  const [goal, setGoal] = useState(contract.goal)
  const [instructions, setInstructions] = useState(contract.instructions)

  const handleSetDue = interval => () => {
    const start = moment(contract.startDate)
    onChange({
      dueDate: start.add(1, interval).toDate()
    })
  }

  const handleSetStart = v => {
    switch (v) {
      case "today":
        onChange({ startDate: new Date() })
        break
      case "month":
        onChange({
          startDate: moment()
            .add(1, "months")
            .startOf("month")
            .toDate()
        })
        break
      default:
        break
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Container>
        <Section icon="Award" title={"What is your goal?"}>
          <TextField
            required
            name="goal"
            placeholder="Lose weight, start a blog etc."
            fullWidth
            autoFocus={!goal}
            value={goal}
            onChange={e => {
              setGoal(e.target.value)
              onChange({ goal: e.target.value })
            }}
          />
        </Section>

        <Section icon="Check" title="What will you do?">
          <ListEditor
            items={contract.steps}
            onChange={steps => {
              onChange({ steps })
            }}
            blankItem={{ description: "" }}
            validateItem={item =>
              item.description && item.description.length > 0
            }
            renderItem={({ onChange, item }) => {
              return (
                <TextField
                  required
                  fullWidth
                  readOnly
                  multiline
                  type={"text"}
                  name={"description"}
                  placeholder={"I will..."}
                  autoComplete={"off"}
                  value={item.description}
                  onChange={e => onChange({ description: e.target.value })}
                />
              )
            }}
          />
        </Section>
        <Section icon="Calendar" title="How long do you need?">
          <Row>
            <h3>Start</h3>
            <Button
              variant="outlined"
              style={{ margin: "0px 5px" }}
              onClick={() => handleSetStart("today")}
            >
              Today
            </Button>
            <Button
              variant="outlined"
              style={{ margin: "0px 5px" }}
              onClick={() => handleSetStart("month")}
            >
              {renderDate(
                moment()
                  .add(1, "months")
                  .startOf("month")
                  .toDate()
              )}
            </Button>
          </Row>
          <KeyboardDatePicker
            margin="normal"
            format="MM/dd/yyyy"
            placeholder="Select a start date"
            value={contract.startDate || null}
            onChange={startDate => onChange({ startDate })}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
          <Row>
            <h3>Finish</h3>
            <Button
              variant="outlined"
              style={{ margin: "0px 5px" }}
              onClick={handleSetDue("weeks")}
            >
              1 week
            </Button>
            <Button
              variant="outlined"
              style={{ margin: "0px 5px" }}
              onClick={handleSetDue("months")}
            >
              1 month
            </Button>
            <Button
              variant="outlined"
              style={{ margin: "0px 5px" }}
              onClick={handleSetDue("years")}
            >
              1 year
            </Button>
          </Row>
          <KeyboardDatePicker
            margin="normal"
            format="MM/dd/yyyy"
            disablePast={true}
            minDate={contract.startDate}
            placeholder="Select an end date"
            value={contract.dueDate || null}
            onChange={dueDate => onChange({ dueDate })}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
        </Section>
        <Section icon="Info" title="Anything else?">
          <TextField
            multiline
            name="instructions"
            placeholder="Additional terms, exceptions, rewards or penalties etc."
            fullWidth
            value={instructions}
            onChange={e => {
              setInstructions(e.target.value)
              onChange({ instructions: e.target.value })
            }}
          />
        </Section>
        <Section icon="Image" title="Design">
          <BackgroundPicker
            selectedPhotoId={contract.photoId}
            onChange={photoId => onChange({ photoId })}
          />
        </Section>
        <Section icon="Settings" title="Manage">
          <DeleteAction contract={contract} />
        </Section>
      </Container>
    </MuiPickersUtilsProvider>
  )
}

export default ContractForm
