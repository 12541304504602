import React, { useState } from "react"
import styled from "styled-components"
import * as Icon from "react-feather"
import { Button, Avatar, Menu, MenuItem, TextField } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import useMe from "./hooks/useMe"
import { Col } from "./ui"
const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  h1 {
    margin: 0px;
    font-size: 1em;
  }
  .MuiAvatar-root {
    width: 50px;
    height: 50px;
  }
`

export const UserAvatar = ({ user, ...props }) => {
  if (user.photoURL) {
    return <Avatar src={user.photoURL} {...props} />
  } else if (user.displayName) {
    const initials = user.displayName
      .split(" ")
      .map(n => n[0])
      .join("")
    return <Avatar {...props}>{initials}</Avatar>
  } else {
    return <Avatar></Avatar>
  }
}

const Profile = () => {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const { user, signOut } = useMe()

  const handleAuthToggle = () => {
    if (user) {
      signOut()
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <ProfileContainer>
      {!user || user.isAnonymous ? (
        <Avatar onClick={handleClick}>
          <Icon.User />
        </Avatar>
      ) : (
        <UserAvatar user={user} onClick={handleClick} />
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {user && !user.isAnonymous ? (
          <MenuItem onClick={handleAuthToggle}>Logout</MenuItem>
        ) : (
          <MenuItem onClick={() => history.push("/auth")}>Sign in</MenuItem>
        )}
      </Menu>
      <Col style={{ marginLeft: 10 }}>
        <h1>My</h1>
        <h1>Goals</h1>
      </Col>
      {/* <Button onClick={handleAuthToggle}>{user ? "Logout" : "Sign in"}</Button> */}
    </ProfileContainer>
  )
}

export default Profile
