import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import Contract from "./Contract"
import { createNewGoalContractDoc } from "./hooks/useContract"
import * as Icon from "react-feather"
import { Col, Row } from "./ui"
import examples from "./data/examples"
import { TextField, Button } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import useMe from "./hooks/useMe"

const pulse = keyframes`
  0%   { opacity:1; }
  50%  { opacity:.5; }
  100% { opacity:1; }
`

const Header = styled.header`
  background-color: #82b0ce;
  /* background-color: #8ac07e; */
  /* border-top: 1px solid black;
  border-bottom: 1px solid black; */
  padding: 40px 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23090909' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");

  h1 {
    margin: 0px;
    margin-bottom: 10px;
    /* text-align: center; */
    font-size: 3em;
    /* text-transform: uppercase; */
  }
  p {
    margin: 0px;
  }

  .MuiButton-containedPrimary {
    flex-shrink: 0;
  }
`

const ExampleContainer = styled(Row)`
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  @media (max-width: 900px) {
    justify-content: center;
    flex-wrap: wrap;

    > div {
      margin-top: 10px;
    }
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
`

const PitchContainer = styled(Col)`
  flex-grow: 0;
  width: auto;
  align-items: flex-start;
  text-align: left;
  line-height: 2.5em;

  p {
    font-size: 1.3em;
    line-height: 1.3em;
  }
  @media (maxwidth: 500px) {
    .pitchText {
      flex-direction: column;
      justify-content: center;
    }
  }
`

const InputContainer = styled(Row)`
  /* flex-wrap: wrap; */
  margin-top: 10px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.19) 8px 12px 40px,
    rgba(0, 0, 0, 0.23) 8px 6px 12px;

  svg {
    animation: ${pulse} 1s ease-in-out 0s infinite;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    svg {
    }
    input {
      text-align: center;
    }
    button {
      margin-top: 10px;
    }
  }
  /* padding: 10px; */
`

const GoalInput = () => {
  const [goal, setGoal] = useState("")
  const history = useHistory()
  const [processing, setProcessing] = useState(false)
  const { user, signInAnonymously } = useMe()

  const handleStart = async () => {
    setProcessing(true)
    if (!user) {
      await signInAnonymously()
    }
    const doc = await createNewGoalContractDoc({ goal })
    setProcessing(false)
    console.log("navigating to...", `/goal/${doc.id}/edit`)
    history.push(`/goal/${doc.id}/edit`)
  }

  return (
    <InputContainer>
      <Row>
        <Icon.ChevronRight size={24} />
        <TextField
          required
          name="goal"
          value={goal}
          onChange={e => {
            setGoal(e.target.value)
          }}
          placeholder="What is your goal?"
          size="medium"
          fullWidth
          style={{
            borderBottom: 0,
            marginRight: 10
          }}
        />
      </Row>
      <Button
        disabled={processing}
        variant="contained"
        color="primary"
        onClick={handleStart}
      >
        {!processing ? "Create Goal Contract" : "Creating..."}
      </Button>
    </InputContainer>
  )
}
const EmojiContainer = styled.div`
  font-size: 72px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Emoji = () => {
  const [index, setIndex] = useState(0)
  const emojis = ["🚀", "🏋️‍♀️", "🏁", "📚", "🎓", "💸", "❤️", "🏆", "🎨"]
  useEffect(() => {
    const i = setInterval(() => {
      setIndex(index => (index < emojis.length - 1 ? index + 1 : 0))
    }, 1000)
    return () => clearInterval(i)
  }, [emojis.length])
  return <EmojiContainer>{emojis[index]}</EmojiContainer>
}

const Home = () => {
  return (
    <Container>
      <Header>
        {/* <Confetti /> */}
        <PitchContainer>
          <Row className="pitchText">
            {/* <Emoji /> */}
            <Col style={{ marginLeft: 0 }}>
              <h1>
                Commit to your&nbsp;
                {new Date().getMonth() === 0 ? "resolutions" : "goals"}
              </h1>
              <p>
                Turn your goals into contracts to gain clarity, commitment, and
                accountability
              </p>
              {/* <p>
                Start a goal contract to gain clarity, commitment, and
                accountability for&nbsp;{new Date().getFullYear()}.
              </p> */}
            </Col>
          </Row>
          <GoalInput />
        </PitchContainer>
      </Header>
      <Row
        style={{
          // justifyContent: "center",
          paddingLeft: 20,
          backgroundColor: "#000",
          color: "#fff"
        }}
      >
        <Icon.Award size={36} />
        <h1
          style={{
            textTransform: "uppercase"
          }}
        >
          Example Contracts
        </h1>
      </Row>
      <ExampleContainer>
        {examples.map((example, i) => (
          <Contract key={i} contract={example} maxWidth={600} />
        ))}
      </ExampleContainer>
    </Container>
  )
}

export default Home
