import React from "react"
import styled from "styled-components"
import * as Icon from "react-feather"
import useMe from "../hooks/useMe"
import {
  Avatar,
  TextareaAutosize,
  TextField,
  IconButton,
  Button
} from "@material-ui/core"
import { Row, Col } from "../ui"
import { UserAvatar } from "../Profile"

const Container = styled.div`
  flex-shrink: 0;
  min-width: 420px;
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const GoalJournal = ({ contract }) => {
  const { user } = useMe()
  if (!user) return null
  return (
    <Container>
      <Row style={{ alignItems: "flex-start" }}>
        <UserAvatar user={user} style={{ marginRight: 10 }} />
        <TextField
          multiline
          rowsMax="4"
          rows={3}
          placeholder={`How's your goal going?`}
          value={""}
          style={{ flexGrow: 1 }}
          onChange={() => {}}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ alignSelf: "flex-end" }}
        >
          <Icon.Send />
          Post update
        </Button>
      </Row>
    </Container>
  )
}

export default GoalJournal
