import React, { useState } from "react"
import styled from "styled-components"
import * as Icon from "react-feather"
import { Button, CircularProgress } from "@material-ui/core"
import { useHistory, NavLink } from "react-router-dom"
import useContracts from "./hooks/useContracts"
import useMe from "./hooks/useMe"
import {
  createNewGoalContractDoc,
  getContractStatus,
} from "./hooks/useContract"
import { Thumb } from "./ui/Background"
import { Row, Col, renderDate } from "./ui"
import Profile from "./Profile"
import ContractStatus from "./ContractStatus"
import Logo from "./ui/Logo"

const ContractsScrollContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  overflow-x: scroll;
  padding: 10px;
  margin-right: 10px;
`

const DashBarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  background-color: #e7e7e7;
  align-items: center;

  .MuiButton-label {
    text-transform: upperacase !important;
    font-weight: 900 !important;
  }
`

const ContractsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  background-color: #e7e7e7;
  flex-grow: 0;
  max-width: calc(100% - 100px);
  overflow: hidden;
  h1,
  h2 {
    font-size: 1.3em;
  }

  @media (max-width: 500px) {
    justify-content: center;
  }
`

const ContractThumbContainer = styled(NavLink)`
  display: flex;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  margin-right: 10px;
  padding: 0px;
  flex-shrink: 0;
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 8px 12px 40px, rgba(0, 0, 0, 0.1) 8px 6px 12px;

  /* clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%); */
  &.active {
    border: 1px solid #000;
  }

  h2 {
    margin: 0px;
    max-width: 240px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.2em;
    text-overflow: ellipsis;
  }
`

const ContractThumb = ({ contract }) => {
  // const progress = getProgress(contract.startDate, contract.dueDate)

  return (
    <ContractThumbContainer
      to={`/goal/${contract.id}`}
      activeClassName="active"
    >
      <Thumb
        size={40}
        photoId={contract.photoId}
        style={{ borderRadius: "50%" }}
      />

      <Row style={{ marginRight: 10, flexWrap: "nowrap", overflow: "hidden" }}>
        <Col>
          <Row>
            <strong>
              <em>{renderDate(contract.dueDate)}</em>
            </strong>
            <ContractStatus contract={contract} />
          </Row>
          <h2>{contract.goal}</h2>
        </Col>
      </Row>
    </ContractThumbContainer>
  )
}

const getProgress = (start, end) => {
  const s = start.getTime() // start
  const e = end.getTime() // end
  const n = new Date().getTime() // now

  if (n < s) return 0 // hasn't started yet
  if (n > e) return 1 // we done

  const p = (n - s) / (e - s)
  return p
}

const Contracts = ({ user }) => {
  const [contracts, loading, error] = useContracts(user)

  const sortByDate = (a, b) =>
    a.dueDate.getTime() > b.dueDate.getTime() ? 1 : -1

  const hasStarted = (c) => {
    const { status } = getContractStatus(c)
    return status === "in progress" || status === "upcoming"
  }

  const inProgress = contracts.filter(hasStarted).sort(sortByDate)
  const other = contracts.filter((c) => !hasStarted(c)).sort(sortByDate)

  const contractsSorted = [...inProgress, ...other]

  return (
    <ContractsContainer>
      {loading ? <CircularProgress /> : null}
      {error ? <div>{error.toString()}</div> : null}

      <ContractsScrollContainer>
        {contracts
          ? contractsSorted.map((contract) => {
              return <ContractThumb contract={contract} key={contract.id} />
            })
          : null}
        {/* <div style={{ width: "100%", position: "absolute", bottom: 0 }}>
          <LinearProgress variant="determinate" value={30} />
        </div> */}
      </ContractsScrollContainer>
      {/* <IconButton
        icon={open ? "ChevronsUp" : "ChevronsDown"}
        onClick={toggleOpen}
        style={{ position: "absolute", right: 20 }}
      /> */}
    </ContractsContainer>
  )
}

const NewGoalButton = () => {
  const history = useHistory()
  const { user, signInAnonymously } = useMe()
  const [processing, setProcessing] = useState(false)

  const handleNewGoal = async () => {
    setProcessing(true)
    if (!user) {
      await signInAnonymously()
    }
    const doc = await createNewGoalContractDoc()
    setProcessing(false)
    history.push(`/goal/${doc.id}/edit`)
  }

  return (
    <Button
      variant="outlined"
      onClick={handleNewGoal}
      style={{
        flexShrink: 0,
        height: 40,
        margin: "0px 10px",
        marginLeft: "auto",
      }}
    >
      <Icon.Plus />
      {processing ? "Creating..." : "New Goal"}
    </Button>
  )
}

const DashBar = () => {
  const { user } = useMe()
  return (
    <DashBarContainer>
      <Logo />
      <Profile />
      {user ? <Contracts user={user} /> : null}
      <NewGoalButton />
    </DashBarContainer>
  )
}

export default DashBar
