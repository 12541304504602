import styled from "styled-components"
import * as Icon from "react-feather"
import React, { useState } from "react"
import { Dialog, Button } from "@material-ui/core"
import {
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton
} from "react-share"
import { Col, Row, renderDate } from "./ui"
import { useParams, useHistory } from "react-router-dom"
import useContract, { getContractURL } from "./hooks/useContract"

const Container = styled.div`
  padding: 20px;
`

export const ShareSheet = ({ contract }) => {
  const url = getContractURL(contract)
  const line = `I signed a goal contract to "${contract.goal}" by ${renderDate(
    contract.dueDate
  )}!`

  return (
    <Col>
      <TwitterShareButton url={url} title={line}>
        <Button variant="outlined" fullWidth style={{ marginRight: 10 }}>
          <Icon.Twitter size={16} style={{ marginRight: 10 }} />
          Twitter
        </Button>
      </TwitterShareButton>
      <FacebookShareButton url={url} quote={line}>
        <Button variant="outlined" fullWidth style={{ marginRight: 10 }}>
          <Icon.Facebook size={16} style={{ marginRight: 10 }} />
          Facebook
        </Button>
      </FacebookShareButton>
      <EmailShareButton url={url} subject={line}>
        <Button variant="outlined" fullWidth style={{ marginRight: 10 }}>
          <Icon.Mail size={16} style={{ marginRight: 10 }} />
          Email
        </Button>
      </EmailShareButton>
    </Col>
  )
}

const ShareModal = ({ contract }) => {
  const history = useHistory()
  const url = getContractURL(contract)
  const line = `Check out my goal contract`
  return (
    <Dialog open={true} onClose={() => history.goBack()}>
      <Container>
        <Row style={{ justifyContent: "center" }}>
          <Icon.Share style={{ marginRight: 10 }} size={16} />
          <h3>Share your goal</h3>
        </Row>
        <p>Something about sharing/accountability</p>
        <TwitterShareButton url={url} title={line}>
          <Button variant="outlined" fullWidth style={{ marginBottom: 10 }}>
            <Icon.Twitter size={16} style={{ marginRight: 10 }} />
            Twitter
          </Button>
        </TwitterShareButton>
        <FacebookShareButton url={url} quote={line}>
          <Button variant="outlined" fullWidth style={{ marginBottom: 10 }}>
            <Icon.Facebook size={16} style={{ marginRight: 10 }} />
            Facebook
          </Button>
        </FacebookShareButton>
        <EmailShareButton url={url} subject={line}>
          <Button variant="outlined" fullWidth style={{ marginBottom: 10 }}>
            <Icon.Mail size={16} style={{ marginRight: 10 }} />
            Share via email
          </Button>
        </EmailShareButton>
      </Container>
    </Dialog>
  )
}

const ShareModalContainer = () => {
  const { id } = useParams()
  const { loading, error, contract } = useContract(id)
  if (loading) return <div>Loading...</div>
  if (error) return <div>{error.toString()}</div>
  return <ShareModal contract={contract} />
}

export default ShareModalContainer
