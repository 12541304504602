import React from "react"
import styled from "styled-components"
import { getContractStatus } from "./hooks/useContract"

const Container = styled.div`
  display: inline-flex;
  width: auto;
  flex-direction: column;
  background-color: ${p => p.statusColor};
  margin: 0px 4px;
  text-transform: uppercase;
  font-size: 13px;
`

const ContractStatus = ({ contract, style }) => {
  const { status, color } = getContractStatus(contract)
  return (
    <Container style={style} statusColor={color}>
      {status}
    </Container>
  )
}

export default ContractStatus
