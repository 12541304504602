import React from "react"
import * as Icons from "react-feather"
import { Button } from "@material-ui/core"

const IconButton = ({ icon, label, size, onClick, style, ...props }) => {
  const Icon = Icons[icon]
  if (!label)
    return (
      <Icon
        size={size}
        strokeWidth={3}
        onClick={onClick}
        style={{ cursor: "pointer", ...style }}
      />
    )
  return (
    <Button onClick={onClick} style={style} {...props}>
      <Icon size={size} strokeWidth={3} />
      {label ? <span>{label}</span> : null}
    </Button>
  )
}

IconButton.defaultProps = {}

export default IconButton
