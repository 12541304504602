import React from "react"

import styled from "styled-components"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { db } from "./api/db"
import Contract from "./Contract"
import { parseContract } from "./hooks/useContract"
import { Row } from "./ui"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > div {
    margin: 10px;
  }
`

const Signed = () => {
  const query = db.collection("contract")
  const [contracts, loading, error] = useCollectionData(query)

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error...</div>

  const signed = contracts
    .map(parseContract)
    .filter(
      c =>
        c.goal.toLowerCase().indexOf("text") === -1 &&
        c.signature?.date &&
        c.signature?.name?.toLowerCase() !== "mark johnson"
    )
    .sort((a, b) =>
      a.signature.date.getTime() > b.signature.date.getTime() ? -1 : 1
    )
  return (
    <>
      <h1>{signed.length} signed contracts</h1>
      <Container>
        {signed.map(contract => (
          <Contract key={contract.id} contract={contract} maxWidth={500} />
        ))}
      </Container>
    </>
  )
}

export default Signed
