import React, { useState, useEffect } from "react"
import pluralize from "pluralize"
import styled from "styled-components"
import * as Icon from "react-feather"
import Sign from "./Sign"
import Confetti from "react-confetti"
import { Row, renderDate } from "./ui"
import Background from "./ui/Background"
import useWindowSize from "@react-hook/window-size"
import ContractStatus from "./ContractStatus"

const ContractWrap = styled.div`
  text-align: center;
  background-color: #fff;
  max-width: 500px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.33) 8px 12px 40px,
    rgba(0, 0, 0, 0.33) 8px 6px 12px;

  h1 {
    font-weight: 800;
    width: 100%;
    text-align: center;
    font-size: 2.5em;
    line-height: 1em;
    margin: 0px;
    text-align: center;
    text-transform: uppercase;
  }
  ul {
    list-style: none;
    text-align: left;
    padding: 0px;
    margin: 0px;
    li {
      padding: 10px 0px;
      display: flex;
      align-items: center;
      font-size: 1em;
      font-weight: 400;
      border-bottom: 1px solid #000;
      .MuiCheckbox-root {
        padding: 0px;
        margin-right: 5px;
      }
    }
  }
`

const ContentContainer = styled.div`
  padding: 30px;
  position: relative;
`

export const Contract = ({ contract, permissions }) => {
  if (!contract) return null
  const validSteps = contract.steps.filter(
    item => item.description && item.description.length > 0
  )
  return (
    <ContractWrap>
      <ContentContainer>
        <ContractStatus
          contract={contract}
          style={{ position: "absolute", right: 10, top: 10 }}
        />
        <Row style={{ justifyContent: "center", marginBottom: 4 }}>
          <Icon.Award />
          <h1 style={{ width: "auto", fontSize: "1.3em", fontWeight: 500 }}>
            goal contract
          </h1>
        </Row>
        <Row>
          {/* <Icon.Award
            strokeWidth={2}
            size={50}
            style={{ marginTop: 0, marginLeft: -10 }}
          /> */}
          <h1>{contract.goal}</h1>
        </Row>
        <h3>
          {renderDate(contract.startDate)} &mdash;
          {renderDate(contract.dueDate)} &bull;{" "}
          {pluralize("term", contract.steps.length, true)}
        </h3>

        {validSteps.length > 0 ? (
          <>
            <ul>
              {validSteps.map((step, i) => {
                return (
                  <li key={i}>
                    {/* <Checkbox /> */}
                    <Icon.ChevronRight size={16} style={{ marginRight: 5 }} />
                    <span>{step.description}</span>
                  </li>
                )
              })}
            </ul>
          </>
        ) : null}
        {contract.instructions ? (
          <div
            style={{
              whiteSpace: "pre-wrap",
              textAlign: "left",
              margin: "10px 0px"
            }}
          >
            {contract.instructions}
          </div>
        ) : null}
      </ContentContainer>
      <Sign contract={contract} permissions={permissions} />
    </ContractWrap>
  )
}

const ContractContainer = ({ contract, permissions, maxWidth }) => {
  const [showConfetti, setShowConfetti] = useState(false)
  const [width, height] = useWindowSize()
  const previewWidth = Math.min(maxWidth, width)

  // show confettie for 7 seconds after signing (TODO: would be nice if this faded out rather than just disapearing)
  useEffect(() => {
    if (
      contract.completedDate // &&new Date().getTime() - contract.completedDate.getTime() < 5000
    ) {
      setShowConfetti(true)
    }
  }, [contract.completedDate])

  return (
    <Background
      photoId={contract.photoId || "9SoCnyQmkzI"}
      width={previewWidth}
      style={{ padding: 10, position: "relative", overflow: "hidden" }}
    >
      {showConfetti ? <Confetti width={width} height={height} /> : null}
      <Contract permissions={permissions} contract={contract} />
    </Background>
  )
}

ContractContainer.defaultProps = {
  maxWidth: 900
}

export default ContractContainer
